html{
  scroll-behavior: smooth;
  font-size: 14pt;
  overflow-x: hidden;
  width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Roboto' !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (max-width: 400px){
  html{
    font-size: 12pt;
  }
}

:root{
  --page-size: clamp(300px, 80%, 1500px);
  --page-center-size: clamp(300px, 60%, 1000px);
}