.aboutAssistantMain {
  width: 100%;
  min-height: 90vh;
  background-image: url('../../assets/gradient.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10rem;
  gap: 3em;
}

.headText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cardsList {
  width: var(--page-size);
  display: flex;
  flex-direction: row;
  gap: 3em;
  justify-content: space-evenly;
  margin-bottom: 2em;
}

@media (max-width: 800px) {
  .cardsList{
    flex-direction: column;
  }

  .aboutAssistantMain {
    padding: 3em;
    align-items: center;
  }
}