.header {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  width: 100%;
  background-color: transparent;
  position: fixed;
  z-index: 1000;
  top: 0;
}

.headerWrapper {
  display: flex;
  justify-content: flex-end;
  width: 85%;
  max-width: 1500px;
  background-color: transparent;
}

.nav {
  display: flex;
  margin-right: 5rem;
  justify-content: space-evenly;
  align-items: center;
}

.link {
  margin-left: 30px;
  margin-top: 30px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
}

.logo {
  max-width: 90px;
  max-height: 90px;
  margin-top: 0.2em;
}

.logoWrapper{
  display: flex;
  justify-content: flex-start;
  margin-left: 7rem;
}

@media (max-width: 800px){
  .headerWrapper{
    justify-content: space-between;
    align-items: center;
  }

  .logoWrapper{
    margin-left: 0;
  }

  .link{
    margin-left: 1rem;
    margin-top: 0;
  }
}
