.head {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100vh;
}

.headWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1990px;
}

.content {
  max-width: 900px;
  margin: 10rem;
}

.button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  background-color: #2AA24C;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.image-container {
  max-width: 40%;
}

.img {
  width: 100%;
  height: auto;
}

@media (max-width: 800px){
  .image-container{
    position: absolute;
    z-index: -1;
    opacity: 0.1;
  }

  .content{
    margin: 8rem 0rem 2rem 0rem;
  }

  .headWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}